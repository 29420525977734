import { graphql, useStaticQuery } from "gatsby";
import { get, isEmpty, isNil, keyBy } from "lodash";
import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import { display } from "styled-system";

import AspectRatioWrapper from "./aspectRatioWrapper";
import { CategoryLink, PostLink } from "./link";

interface MarkdownRemark {
  id: string;
  frontmatter: {
    title: string;
    category: string[];
    thumbnail: string;
    lang: string;
  };
  excerpt: string;
}

interface CategoriesYaml {
  value: string;
  label_en: string;
  label_zh_hant: string;
  label_zh_hans: string;
}

interface QueryData {
  allMarkdownRemark: {
    edges: {
      node: MarkdownRemark;
    }[];
  };
  allCategoriesYaml: {
    edges: {
      node: CategoriesYaml;
    }[];
  };
}

const useDataHooks = (): {
  allCategoriesById: { [categoryId: string]: CategoriesYaml };
  allPagesByCategory: { [categoryId: string]: MarkdownRemark[] };
} => {
  const queryData: QueryData = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { category: { ne: null } } }) {
        edges {
          node {
            id
            frontmatter {
              title
              category
              thumbnail
              lang
            }
            excerpt
          }
        }
      }
      allCategoriesYaml {
        edges {
          node {
            value
            label_en
            label_zh_hant
            label_zh_hans
          }
        }
      }
    }
  `);

  const allCategories = queryData.allCategoriesYaml.edges.map(
    ({ node }): CategoriesYaml => node
  );
  const allCategoriesById: { [catId: string]: typeof allCategories[0] } = keyBy(
    allCategories,
    "value"
  );

  const allPages = queryData.allMarkdownRemark.edges.map(
    ({ node }): MarkdownRemark => node
  );
  const allPagesByCategory = allCategories
    .map(({ value }): string => value)
    .map(
      (catId): { [catId: string]: MarkdownRemark[] } => ({
        [catId]: allPages.filter(
          (page): boolean =>
            get(page, "frontmatter.category", []).includes(catId)
        ),
      })
    )
    .reduce(
      (obj, val): { [catId: string]: MarkdownRemark[] } =>
        Object.assign(obj, val),
      {}
    );

  return { allCategoriesById, allPagesByCategory };
};

const ResponsiveDisplayWrapper = styled.div`
  ${display};
`;

const StyledPostLink = styled(PostLink)`
  &,
  &:hover,
  &:visited {
    color: white;
  }
`;

const CategoryArticle = styled(
  ({ data, ...restProps }): ReactElement => (
    <Flex flexDirection={["column", "column", "row"]} {...restProps}>
      <Box width={[1, 1, 200]} flex="0 0 auto">
        <StyledPostLink articleId={data.id}>
          <ResponsiveDisplayWrapper display={["none", "none", "block"]}>
            <AspectRatioWrapper ratio={1}>
              <img
                src={data.frontmatter.thumbnail}
                style={{ objectFit: "cover" }}
              />
            </AspectRatioWrapper>
          </ResponsiveDisplayWrapper>
          <ResponsiveDisplayWrapper display={["block", "block", "none"]}>
            <img
              src={data.frontmatter.thumbnail}
              style={{
                margin: 0,
                width: "100%",
                height: "100px",
                objectFit: "cover",
              }}
            />
          </ResponsiveDisplayWrapper>
        </StyledPostLink>
      </Box>
      <Flex flexDirection="column" pl={[0, 0, 4]}>
        <StyledPostLink articleId={data.id}>
          <StyledH1 fontSize={["2rem", "2rem", "2.25rem"]} mb={[2, 2, 3]}>
            {data.frontmatter.title}
          </StyledH1>
        </StyledPostLink>
        <StyledPostLink articleId={data.id}>
          <div style={{ textAlign: "justify" }}>{data.excerpt}</div>
        </StyledPostLink>
      </Flex>
    </Flex>
  )
)`
  &:last-child {
    margin-bottom: 0;
  }
`;

CategoryArticle.propTypes = {
  data: PropTypes.object.isRequired,
};

const SingleCategoryArticles = ({
  data: pages,
  ...restProps
}): ReactElement => (
  <Flex flexDirection="column" {...restProps}>
    {pages.map(
      (page): ReactElement => (
        <CategoryArticle data={page} mb={3} key={page.id} />
      )
    )}
  </Flex>
);

SingleCategoryArticles.propTypes = {
  data: PropTypes.object.isRequired,
};

const StyledSection = styled(Flex).attrs({
  as: "section",
  flexDirection: "column",
})`
  border-top: 1px solid white;

  &:empty {
    display: none;
  }
`;

const StyledCategoryLink = styled(CategoryLink)`
  &,
  &:hover,
  &:visited {
    color: white;
  }
`;

const StyledH1 = styled(Box).attrs({ as: "h1" })``;

const StyledSectionItem = styled(Flex)`
  &:first-child > ${StyledH1} {
    margin-top: 0;
  }
`;

const CategoryArticles = ({
  category,
  language,
  currentPostId = null,
  ...restProps
}: {
  category: string | string[] | null;
  language: string;
  currentPostId?: string | null;
}): ReactElement => {
  if (isNil(category)) {
    return null;
  }

  const data = useDataHooks();
  return (
    <StyledSection pt={3} {...restProps}>
      {(Array.isArray(category) ? category : [category]).map(
        (catId): ReactElement => {
          const filteredData = data.allPagesByCategory[catId].filter(
            (page): boolean =>
              page.id !== currentPostId &&
              (page.frontmatter.lang === language ||
                page.frontmatter.lang === null)
          );

          if (isEmpty(filteredData)) {
            return null;
          } else {
            return (
              <StyledSectionItem key={catId} flexDirection="column">
                <StyledH1 mt={4}>
                  <StyledCategoryLink category={catId}>
                    {
                      data.allCategoriesById[catId][
                        `label_${language.replace("-", "_")}`
                      ]
                    }
                  </StyledCategoryLink>
                </StyledH1>
                <SingleCategoryArticles data={filteredData} />
              </StyledSectionItem>
            );
          }
        }
      )}
    </StyledSection>
  );
};

CategoryArticles.propTypes = {
  category: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  language: PropTypes.string.isRequired,
  currentPostId: PropTypes.string,
};

export default React.memo(CategoryArticles);
