import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { PureComponent, ReactElement } from "react";
import Slider from "react-slick";
import { Flex } from "rebass";
import styled from "styled-components";

import CategoryArticles from "../components/categoryArticles";
import ContentWrapper from "../components/contentWrapper";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { getLanguageFromPath } from "../helpers/languageHelper";

interface GraphQLProps {
  data: {
    markdownRemark: {
      id: string;
      frontmatter: {
        title: string;
        layout: string;
        slideshow: {
          slide: string;
        }[];
      };
      html: string;
      excerpt: string;
      fileAbsolutePath: string;
    };
    allCategoriesYaml: {
      edges: {
        node: {
          value: string;
        };
      }[];
    };
  };
  location: WindowLocation;
}

const StyledSlider = styled(Slider)`
  .slick-slide > div {
    display: flex;
  }
`;

const StyledImage = styled.img`
  object-fit: cover;
  margin: 0;
  height: calc(100vh - 58.1px);

  @media only screen and (max-width: 639px) {
    height: calc(100vh - 98.1px);
  }
`;

const StyledCategoryArticles = styled(CategoryArticles)`
  border-top: 0 none;
`;

class HomeTemplate extends PureComponent<GraphQLProps> {
  private static convertRemToPixels = (rem: number): number => rem * 16;
  // rem * parseFloat(getComputedStyle(window.document.documentElement).fontSize);

  public render(): ReactElement {
    const { data, location } = this.props;
    const {
      html,
      frontmatter: { slideshow },
    } = data.markdownRemark;
    const categoryIds = data.allCategoriesYaml.edges.map(
      ({ node }): string => node.value
    );
    const lang = getLanguageFromPath(location.pathname);

    return (
      <Layout location={location} fullWidth={true} noFooter={true}>
        <SEO
          title={
            lang === "zh-hant" ? "主頁" : lang === "zh-hans" ? "主页" : "Home"
          }
        />
        <Flex mx={0} mt={-3} flexDirection="column">
          {slideshow && slideshow.length && (
            <StyledSlider
              arrows={false}
              infinite={true}
              autoplay={true}
              autoplaySpeed={5000}
              centerMode={false}
              adaptiveHeight={true}
              pauseOnHover={false}
              slidesToShow={1}
              lazyLoad="progressive"
              responsive={[
                {
                  breakpoint: HomeTemplate.convertRemToPixels(64),
                  settings: { slidesToShow: 1 },
                },
                {
                  breakpoint: HomeTemplate.convertRemToPixels(52),
                  settings: { slidesToShow: 1 },
                },
                {
                  breakpoint: HomeTemplate.convertRemToPixels(40),
                  settings: { slidesToShow: 1 },
                },
              ]}
            >
              {slideshow.map(
                ({ slide }, i): ReactElement => (
                  <StyledImage src={slide} key={i} />
                )
              )}
            </StyledSlider>
          )}
        </Flex>
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
        <ContentWrapper>
          <StyledCategoryArticles category={categoryIds} language={lang} />
        </ContentWrapper>
      </Layout>
    );
  }

  public static propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        layout
        slideshow {
          slide
        }
      }
      html
      excerpt
      fileAbsolutePath
    }
    allCategoriesYaml {
      edges {
        node {
          value
        }
      }
    }
  }
`;

export default HomeTemplate;
